import { assocPath, dissocPath, path } from 'ramda';
import {
	DELETED_DATA,
	FORMATTED_DATA,
	UPDATED_DATA,
	VALIDATED_EDITOR,
} from '../actions/editorActions';

const initialState = {};

function updateData(state, { payload }) {
	const { newValue, configKey, selectedApp } = payload;
	return assocPath([configKey, selectedApp, 'data'], newValue, state);
}

function deleteData(state, { payload }) {
	const { configKey, selectedApp } = payload;
	return dissocPath([configKey, selectedApp, 'data'], state);
}

function validateEditor(state, { payload }) {
	const { configKey, selectedApp, hasErrors } = payload;
	return assocPath([configKey, selectedApp, 'hasErrors'], hasErrors, state);
}

function formatData(state, { payload }) {
	const { configKey, selectedApp } = payload;
	const oldData = JSON.parse(path([configKey, selectedApp, 'data'], state));
	return assocPath(
		[configKey, selectedApp, 'data'],
		JSON.stringify(oldData, null, '\t'),
		state,
	);
}

// eslint-disable-next-line default-param-last
const editorState = (state = initialState, action) => {
	switch (action.type) {
		case UPDATED_DATA: {
			return updateData(state, action);
		}

		case DELETED_DATA: {
			return deleteData(state, action);
		}

		case VALIDATED_EDITOR: {
			return validateEditor(state, action);
		}

		case FORMATTED_DATA: {
			return formatData(state, action);
		}

		default:
			return state;
	}
};

export { editorState };
