import { getAppData } from '../selectors/getAppData';
import { getIsConfigLoaded } from '../selectors/getIsConfigLoaded';
import { getSelectedAppKey } from '../selectors/getSelectedAppKey';
import { getSelectedKey } from '../selectors/getSelectedKey';
import HttpService from '../services/http-service';
import { snackbarActivated } from './uiActions';
import {
	PATH_DOMAINS,
	PATH_MAINTENANCE,
	SNACKBAR_STATUS_ERROR,
	SNACKBAR_STATUS_SUCCESS,
} from '../constants';
import { checkedHasValidationErrors } from './draftValidationStateActions';

const http = new HttpService();

export const REQUESTED_CONFIGDATA = 'api/REQUESTED_CONFIGDATA';
export const RECEIVED_CONFIGDATA = 'api/RECEIVED_CONFIGDATA';
export const REQUESTED_UPDATE_CONFIGDATA = 'api/REQUESTED_UPDATE_CONFIGDATA';
export const UPDATED_CONFIGDATA = 'api/UPDATED_CONFIGDATA';
export const FAILED_RECEIVING_CONFIGDATA = 'api/FAILED_RECEIVING_CONFIGDATA';
export const FAILED_UPDATING_CONFIGDATA = 'api/FAILED_UPDATING_CONFIGDATA';

export const CONFIG_DRAFT_UPDATED = 'config/CONFIG_DRAFT_UPDATED';
export const CONFIG_LOCAL_DELETED = 'config/CONFIG_LOCAL_DELETED';

export const APP_DATA_DISCARDED = 'config/APP_DATA_DISCARDED';
export const UPDATED_REDIRECTS_DATA = 'config/UPDATED_REDIRECTS_DATA';
export const ADDED_REDIRECT = 'config/ADDED_REDIRECT';
export const REMOVED_REDIRECT = 'config/REMOVED_REDIRECT';
export const REMOVED_EXCLUDED_DOMAIN = 'config/REMOVED_EXCLUDED_DOMAIN';
export const ADDED_LIST_ITEM = 'config/ADDED_LIST_ITEM';
export const REMOVED_LIST_ITEM = 'config/REMOVED_LIST_ITEM';
export const UPDATED_LIST_ITEM = 'config/UPDATED_LIST_ITEM';

export const requestedConfigData = () => ({
	type: REQUESTED_CONFIGDATA,
});

export const receivedConfigData = (key, configData) => ({
	type: RECEIVED_CONFIGDATA,
	payload: {
		key,
		configData,
	},
});

export const failedReceivingData = () => ({
	type: FAILED_RECEIVING_CONFIGDATA,
});

export const requestedUpdateConfigData = () => ({
	type: REQUESTED_UPDATE_CONFIGDATA,
});

export const updatedConfigData = () => ({
	type: UPDATED_CONFIGDATA,
});

export const failedUpdatingConfigData = () => ({
	type: FAILED_UPDATING_CONFIGDATA,
});

export const configDraftUpdated = (newValue, configKey, selectedApp, path) => ({
	type: CONFIG_DRAFT_UPDATED,
	payload: {
		newValue,
		configKey,
		selectedApp,
		PATHS: path,
	},
});

export const configLocalDeleted = configKey => ({
	type: CONFIG_LOCAL_DELETED,
	payload: { configKey },
});

export const appDataDiscarded = (
	configKey,
	selectedApp,
	originalValue,
	pathToDiscard,
) => ({
	type: APP_DATA_DISCARDED,
	payload: {
		configKey,
		selectedApp,
		originalValue,
		pathToDiscard,
	},
});

export const updatedRedirectsData = (
	newValue,
	configKey,
	selectedApp,
	PATHS,
	index,
	prop,
) => ({
	type: UPDATED_REDIRECTS_DATA,
	payload: {
		newValue,
		configKey,
		selectedApp,
		PATHS,
		index,
		prop,
	},
});

export const addedRedirect = (newObject, configKey, selectedApp, PATHS) => ({
	type: ADDED_REDIRECT,
	payload: {
		newObject,
		configKey,
		selectedApp,
		PATHS,
	},
});

export const removedRedirect = (index, configKey, selectedApp, PATHS) => ({
	type: REMOVED_REDIRECT,
	payload: {
		index,
		configKey,
		selectedApp,
		PATHS,
	},
});

export const removedExcludedDomain = (
	domain,
	configKey,
	selectedApp,
	PATHS,
) => ({
	type: REMOVED_EXCLUDED_DOMAIN,
	payload: {
		domain,
		configKey,
		selectedApp,
		PATHS,
	},
});

export const addedListItem = (domain, configKey, selectedApp, PATHS) => ({
	type: ADDED_LIST_ITEM,
	payload: {
		domain,
		configKey,
		selectedApp,
		PATHS,
	},
});

export const removedListItemByIndex = (
	index,
	configKey,
	selectedApp,
	PATHS,
) => ({
	type: REMOVED_LIST_ITEM,
	payload: {
		index,
		configKey,
		selectedApp,
		PATHS,
	},
});

export const updatedListItemByIndex = (
	newValue,
	index,
	configKey,
	selectedApp,
	PATHS,
) => ({
	type: UPDATED_LIST_ITEM,
	payload: {
		newValue,
		index,
		configKey,
		selectedApp,
		PATHS,
	},
});

export const deleteCache = () =>
	function (dispatch, getState) {
		const state = getState();
		const key = getSelectedKey(state);
		if (key) {
			const selectedEnv = getSelectedKey(getState()).split('-')[1];
			http
				.deleteCache(selectedEnv)
				.then(result => {
					if (result) {
						dispatch(
							snackbarActivated(
								'Cache successfully invalidated!',
								SNACKBAR_STATUS_SUCCESS,
							),
						);
					} else {
						dispatch(
							snackbarActivated(
								'Cache could not be invalidated!',
								SNACKBAR_STATUS_ERROR,
							),
						);
					}
				})
				.catch(err => {
					dispatch(snackbarActivated(err.message, SNACKBAR_STATUS_ERROR));
				});
		}
	};

export const fetchConfigData = () =>
	function(dispatch, getState) {
		const state = getState();
		const key = getSelectedKey(state);
		if (key && !getIsConfigLoaded(state, key)) {
			const selectedTenant = getSelectedKey(getState()).split('-')[0];
			const selectedEnv = getSelectedKey(getState()).split('-')[1];
			dispatch(requestedConfigData());
			http
				.getConfigData(selectedTenant, selectedEnv)
				.then(configData => dispatch(receivedConfigData(key, configData)))
				.catch(err => {
					dispatch(snackbarActivated(err.message, SNACKBAR_STATUS_ERROR));
					dispatch(failedReceivingData());
				});
		}
	};

export const updateConfigData = newConfigData =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedTenant = configKey.split('-')[0];
		const selectedEnv = configKey.split('-')[1];
		dispatch(requestedUpdateConfigData());
		http
			.updateConfigData(selectedTenant, selectedEnv, newConfigData)
			.then(() => {
				dispatch(updatedConfigData());
				dispatch(configLocalDeleted(configKey));
				dispatch(fetchConfigData());
				dispatch(
					snackbarActivated(
						'Changes successfully saved!',
						SNACKBAR_STATUS_SUCCESS,
					),
				);
			})
			.catch(err => {
				dispatch(
					snackbarActivated(
						`${err.message} - Check internet connection!`,
						SNACKBAR_STATUS_ERROR,
					),
				);
			});
	};

export const updateConfigDraftData = (newValue, path, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		const mergedPath = PATHS[0].concat(path);
		dispatch(configDraftUpdated(newValue, configKey, selectedApp, mergedPath));
		dispatch(
			checkedHasValidationErrors(getState(), configKey, selectedApp, PATHS),
		);
	};

// argument should be an array of an array
export const discardAppData = pathsToDiscard =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		pathsToDiscard.forEach(pathToDiscard => {
			const originalValue = getAppData(state, pathToDiscard, 'original');
			dispatch(
				appDataDiscarded(configKey, selectedApp, originalValue, pathToDiscard),
			);
		});
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				pathsToDiscard,
			),
		);
	};

export const updateRedirectsData = (newValue, index, prop, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(
			updatedRedirectsData(
				newValue,
				configKey,
				selectedApp,
				PATHS,
				index,
				prop,
			),
		);
		dispatch(
			checkedHasValidationErrors(getState(), configKey, selectedApp, PATHS),
		);
	};

export const addRedirect = (fromValue, toValue, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		const newObject = { from: fromValue, to: toValue };
		dispatch(addedRedirect(newObject, configKey, selectedApp, PATHS));
		dispatch(
			checkedHasValidationErrors(getState(), configKey, selectedApp, PATHS),
		);
	};

export const removeRedirect = (index, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(removedRedirect(index, configKey, selectedApp, PATHS));
		dispatch(
			checkedHasValidationErrors(getState(), configKey, selectedApp, PATHS),
		);
	};

export const addExcludedDomain = (domain, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(addedListItem(domain, configKey, selectedApp, PATHS));
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_MAINTENANCE,
			),
		);
	};

export const removeExcludedDomain = (domain, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(removedExcludedDomain(domain, configKey, selectedApp, PATHS));
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_MAINTENANCE,
			),
		);
	};

export const addDomain = (domain, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(addedListItem(domain, configKey, selectedApp, PATHS));
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_DOMAINS,
			),
		);
	};

export const removeDomain = (index, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(removedListItemByIndex(index, configKey, selectedApp, PATHS));
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_DOMAINS,
			),
		);
	};

export const updateListItem = (newValue, index, PATHS) =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(
			updatedListItemByIndex(newValue, index, configKey, selectedApp, PATHS),
		);
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_DOMAINS,
			),
		);
	};
