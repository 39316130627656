import { equals } from 'ramda';
import { getAppData } from './getAppData';
import { getSelectedAppKey } from './getSelectedAppKey';
import { getConfigKeyData } from './getConfigKeyData';

export function getDataHasChanges(state, paths, appKey, isConfigKeyData) {
	if (isConfigKeyData) {
		const draftData = getConfigKeyData(state);
		const originalData = getConfigKeyData(state, 'original');
		return !equals(draftData, originalData);
	}

	const selectedApp = appKey || getSelectedAppKey(state);
	if (!paths) {
		const dataDraft = getAppData(state, null, null, selectedApp);
		const dataOriginal = getAppData(state, null, 'original', selectedApp);
		const dataHasChanges = !equals(dataDraft, dataOriginal);
		return dataHasChanges;
	}
	const hasChangesArray = paths.map(pathToCheck => {
		const draft = getAppData(state, pathToCheck, null, selectedApp);
		const original = getAppData(state, pathToCheck, 'original', selectedApp);
		if (equals(draft, original)) {
			return false;
		}
		return true;
	});
	const hasChanges = hasChangesArray.some(element => element === true);
	return hasChanges;
}
