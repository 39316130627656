export const ENVIRONMENTS = [
	'PRETEST',
	'TEST',
	'PREPROD',
	'PROD',
	'CH1TEST',
	'CH1PROD',
	'DSTTEST',
	'DSTPROD',
];

export const LOCAL = 'LOCAL';

export const TENANTS = [
	{ name: 'amag', envs: ['PRETEST','TEST', 'PROD'] },
	{ name: 'dealestate', envs: ['PRETEST','TEST', 'PROD'] },
	{ name: 'dealestate2', envs: ['PRETEST', 'TEST', 'PROD', 'DSTTEST', 'DSTPROD'] },
	{ name: 'deepimpact', envs: ['PRETEST','TEST', 'PROD'] },
	{ name: 'holcimpartnernet', envs: ['PRETEST','TEST', 'PROD'] },
	{ name: 'kartause', envs: ['PRETEST','TEST', 'PROD'] },
	{ name: 'mobileid', envs: ['PRETEST','TEST', 'PREPROD', 'PROD'] },
	{ name: 'mobimo', envs: ['PROD','CH1TEST', 'CH1PROD'] }, // CH1TEST missing correct authkey
	{ name: 'stadttheaterlangenthal', envs: ['PRETEST','TEST', 'PROD'] },
];

// paths
export const PATH_TRACKINGCODES = [['thirdPartyConfig']];
export const PATH_REDIRECTS = [['redirect', 'path']];
export const PATH_REDIRECT_WWW = [['redirect', 'www']];
export const PATH_REDIRECT_TLS = [['redirect', 'tls']];
export const PATH_MAINTENANCE = [['maintenance']];
export const PATH_AVAILABLE_DOMAINS = [['domains']];
export const PATH_CUSTOM_CONFIG = [['customConfig']];
export const PATH_MAINTENANCE_EXCLUDED_DOMAINS = [
	['maintenance', 'excludedDomains'],
];
export const PATH_DOMAINS = [['domains']];
export const PATH_AMAG_DEALER_DOMAIN_MAPPING = [
	['customConfig', 'dealerDomainMapping'],
];
export const PATH_REDIRECT_TLS_EXCLUDED_DOMAINS = [
	['redirect', 'tls', 'excludedDomains'],
];
export const PATH_REDIRECT_WWW_EXCLUDED_DOMAINS = [
	['redirect', 'www', 'excludedDomains'],
];

export const PATH_DISCARD_AMAG_DEALERS = [
	PATH_REDIRECT_TLS_EXCLUDED_DOMAINS[0],
	PATH_REDIRECT_WWW_EXCLUDED_DOMAINS[0],
	PATH_AMAG_DEALER_DOMAIN_MAPPING[0],
	PATH_DOMAINS[0],
];

// snackbar
export const SNACKBAR_STATUS_SUCCESS = 'SNACKBAR_STATUS_SUCCESS';
export const SNACKBAR_STATUS_WARNING = 'SNACKBAR_STATUS_WARNING';
export const SNACKBAR_STATUS_ERROR = 'SNACKBAR_STATUS_ERROR';

export const OVERALL_APP = 'OVERALL_APP';
