import { append, assocPath, dissocPath, pathOr, remove } from 'ramda';
import {
	FAILED_RECEIVING_CONFIGDATA,
	RECEIVED_CONFIGDATA,
	REQUESTED_CONFIGDATA,
} from '../actions/configActions';
import {
	COLLAPSE_SELECTED,
	CONFIG_PAGE_SELECTED,
	ENV_SELECTED,
	NOTIFICATION_DELETED,
	SNACKBAR_ACTIVATED,
	SNACKBAR_DISABLED,
	TENANT_SELECTED,
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
} from '../actions/uiActions';

const initialState = {
	selectedTenant: undefined,
	selectedEnvironment: false,
	byKey: {},
	isFetchingConfigData: undefined,
	snackbar: { isActivated: false, notifications: [] },
	userInformation: {
		isLoggedIn: false,
		profile: {},
	},
};

function selectEnv(state, { payload }) {
	return { ...state, selectedEnvironment: payload.env };
}

function selectTenant(state, { payload }) {
	return { ...state, selectedTenant: payload.tenantId };
}

function requestData(state) {
	return { ...state, isFetchingConfigData: true };
}

function receiveData(state) {
	return {
		...state,
		isFetchingConfigData: false,
	};
}

function selectConfigPage(state, { payload }) {
	const { configKey, appKey, configPageKey } = payload;
	const path = [
		'byKey',
		configKey,
		'selectedApp',
		appKey,
		'selectedConfigPage',
	];
	const clearedPath = dissocPath(['byKey', configKey, 'selectedApp'], state);
	return assocPath(path, configPageKey, clearedPath);
}

function selectCollapse(state, { payload }) {
	const path = [
		'byKey',
		payload.configKey,
		'selectedCollapses',
		payload.selectedCollapse,
	];

	const appChangePath = ['byKey', payload.configKey, 'selectedApp'];

	const clearedSelectedAppPath = dissocPath(
		['byKey', payload.configKey, 'selectedApp'],
		state,
	);
	const collapseSelected = assocPath(
		path,
		!pathOr(false, path, state),
		clearedSelectedAppPath,
	);
	const collapseAndAppSelected = assocPath(
		appChangePath,
		payload.selectedCollapse,
		collapseSelected,
	);
	return collapseAndAppSelected;
}

function snackbarActivate(state, { payload }) {
	const { message, status } = payload;
	if (!message && !status) {
		const newState = assocPath(['snackbar', 'isActivated'], true, state);
		return newState;
	}

	const newSnackbar = {
		message,
		status,
	};

	const newArray = append(newSnackbar, state.snackbar.notifications);

	const firstChange = assocPath(['snackbar', 'isActivated'], true, state);
	const secondChange = assocPath(
		['snackbar', 'notifications'],
		newArray,
		firstChange,
	);
	return secondChange;
}

function snackbarDisable(state) {
	return assocPath(['snackbar', 'isActivated'], false, state);
}

function failReceivingData(state) {
	return { ...state, isFetchingConfigData: false };
}

function deleteNotification(state) {
	const newArray = remove(0, 1, state.snackbar.notifications);
	return assocPath(['snackbar', 'notifications'], newArray, state);
}

function userLogIn(state, { payload }) {
	return {
		...state,
		userInformation: { isLoggedIn: true, profile: payload.profile },
	};
}

function userLogOut(state) {
	return {
		...state,
		userInformation: {
			isLoggedIn: false,
			profile: {},
		},
	};
}

// eslint-disable-next-line default-param-last
const ui = (state = initialState, action) => {
	switch (action.type) {
		case ENV_SELECTED: {
			return selectEnv(state, action);
		}

		case TENANT_SELECTED: {
			return selectTenant(state, action);
		}

		case REQUESTED_CONFIGDATA: {
			return requestData(state);
		}

		case RECEIVED_CONFIGDATA: {
			return receiveData(state);
		}

		case CONFIG_PAGE_SELECTED: {
			return selectConfigPage(state, action);
		}
		case COLLAPSE_SELECTED: {
			return selectCollapse(state, action);
		}

		case SNACKBAR_ACTIVATED: {
			return snackbarActivate(state, action);
		}

		case SNACKBAR_DISABLED: {
			return snackbarDisable(state);
		}

		case FAILED_RECEIVING_CONFIGDATA: {
			return failReceivingData(state);
		}

		case NOTIFICATION_DELETED: {
			return deleteNotification(state);
		}

		case USER_LOGGED_IN: {
			return userLogIn(state, action);
		}

		case USER_LOGGED_OUT: {
			return userLogOut(state);
		}

		default:
			return state;
	}
};

export { ui };
