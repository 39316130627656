import { dissocPath } from 'ramda';
import {
	CONFIG_LOCAL_DELETED,
	RECEIVED_CONFIGDATA,
} from '../actions/configActions';

const initialState = {
	byKey: {},
};

function receiveData(state, { payload }) {
	if (state.byKey[payload.key]) {
		return state;
	}
	return {
		...state,
		byKey: {
			...state.byKey,
			[payload.key]: payload.configData,
		},
	};
}

function configLocalDelete(state, { payload }) {
	const { configKey } = payload;
	const absolutePath = ['byKey', configKey];
	return dissocPath(absolutePath, state);
}

// eslint-disable-next-line default-param-last
const configOriginal = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVED_CONFIGDATA: {
			return receiveData(state, action);
		}

		case CONFIG_LOCAL_DELETED: {
			return configLocalDelete(state, action);
		}

		default:
			return state;
	}
};

export { configOriginal };
