import { pathOr } from 'ramda';
import { getSelectedKey } from './getSelectedKey';

export function getSelectedAppKey(state) {
	const appObject = pathOr(
		undefined,
		['ui', 'byKey', getSelectedKey(state), 'selectedApp'],
		state,
	);
	if (appObject) {
		return Object.keys(appObject)[0];
	}
	return undefined;
}
