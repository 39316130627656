import { assocPath, path } from 'ramda';
import { CHECKED_HAS_VALIDATION_ERRORS } from '../actions/draftValidationStateActions';
import { getSuitableValidator } from '../selectors/getSuitableValidator';

const initialState = {};

function checkForErrors(state, { payload }) {
	const { currentState, configKey, selectedApp, PATHS } = payload;
	const validator = getSuitableValidator(PATHS);
	if (validator) {
		const hasErrors = validator(currentState, configKey, selectedApp, PATHS);
		if (hasErrors) {
			return assocPath([configKey, selectedApp, PATHS], true, state);
		}
		if (path([configKey, selectedApp, PATHS], state)) {
			return assocPath([configKey, selectedApp, PATHS], false, state);
		}
	}
	return state;
}

// eslint-disable-next-line default-param-last
const draftValidationState = (state = initialState, action) => {
	switch (action.type) {
		case CHECKED_HAS_VALIDATION_ERRORS: {
			return checkForErrors(state, action);
		}

		default:
			return state;
	}
};

export { draftValidationState };
