import { path } from 'ramda';
import { getSelectedKey } from './getSelectedKey';
import { getSelectedAppKey } from './getSelectedAppKey';
import { OVERALL_APP } from '../constants';

export function getAppData(state, relativePath, original, appKey) {
	const configKey = getSelectedKey(state);
	const selectedApp = appKey || getSelectedAppKey(state);

	const isOverallAppSelected = selectedApp === OVERALL_APP;
	const originalRootPath = isOverallAppSelected
		? ['configOriginal', 'byKey', configKey, 'query']
		: [
			'configOriginal',
			'byKey',
			configKey,
			'query',
			'qry.tenant.get-full',
			'data',
			'private',
			'apps',
			selectedApp,
		];

	const draftRootPath = isOverallAppSelected
		? ['configDraft', 'byKey', configKey, 'query']
		: [
			'configDraft',
			'byKey',
			configKey,
			'query',
			'qry.tenant.get-full',
			'data',
			'private',
			'apps',
			selectedApp,
		];

	if (!relativePath && !original) {
		return path(draftRootPath, state);
	}
	if (!relativePath && original) {
		return path(originalRootPath, state);
	}

	if (configKey && selectedApp) {
		if (original) {
			const absolutePathOriginal = originalRootPath.concat(...relativePath);
			const originalData = path(absolutePathOriginal, state);
			return originalData;
		}
		const absolutePathDraft = draftRootPath.concat(...relativePath);
		const draftData = path(absolutePathDraft, state);
		return draftData;
	}
	return undefined;
}
