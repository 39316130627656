import { isEmpty, path } from 'ramda';

export const ENV_SELECTED = 'ui/ENV_SELECTED';
export const TENANT_SELECTED = 'ui/TENANT_SELECTED';
export const CONFIG_PAGE_SELECTED = 'ui/CONFIG_PAGE_SELECTED';
export const COLLAPSE_SELECTED = 'ui/COLLAPSE_SELECTED';
export const SNACKBAR_ACTIVATED = 'ui/SNACKBAR_ACTIVATED';
export const SNACKBAR_DISABLED = 'ui/SNACKBAR_DISABLED';
export const NOTIFICATION_DELETED = 'ui/NOTIFICATION_DELETED';
export const USER_LOGGED_IN = 'ui/USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'ui/USER_LOGGED_OUT';

export const envSelected = env => ({
	type: ENV_SELECTED,
	payload: {
		env,
	},
});

export const tenantSelected = tenantId => ({
	type: TENANT_SELECTED,
	payload: {
		tenantId,
	},
});

export const configPageSelected = (appKey, configPageKey, configKey) => ({
	type: CONFIG_PAGE_SELECTED,
	payload: {
		appKey,
		configPageKey,
		configKey,
	},
});

export const collapseSelected = (selectedCollapse, configKey) => ({
	type: COLLAPSE_SELECTED,
	payload: {
		selectedCollapse,
		configKey,
	},
});

export const snackbarActivated = (message, status) => ({
	type: SNACKBAR_ACTIVATED,
	payload: { message, status },
});

export const snackbarDisabled = () => ({
	type: SNACKBAR_DISABLED,
});

export const notificationDeleted = () => ({
	type: NOTIFICATION_DELETED,
});

export const userLoggedIn = profile => ({
	type: USER_LOGGED_IN,
	payload: { profile },
});

export const userLoggedOut = () => ({
	type: USER_LOGGED_OUT,
});

export const disableSnackbar = () =>
	function(dispatch, getState) {
		const absolutePath = ['ui', 'snackbar', 'notifications'];
		dispatch(snackbarDisabled());
		setTimeout(() => {
			dispatch(notificationDeleted());
			if (!isEmpty(path(absolutePath, getState()))) {
				setTimeout(() => {
					dispatch(snackbarActivated(undefined, undefined));
				}, 500);
			}
		}, 500);
	};
