import { combineReducers } from 'redux';
import { configDraft } from './configDraft';
import { configOriginal } from './configOriginal';
import { draftValidationState } from './draftValidationState';
import { editorState } from './editorState';
import { ui } from './uiReducer';

const rootReducer = combineReducers({
	ui,
	configOriginal,
	configDraft,
	draftValidationState,
	editorState,
});

export { rootReducer };
