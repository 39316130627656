import { isEmpty, path } from 'ramda';

export function redirectsValidator(state, configKey, selectedApp, PATHS) {
	const absolutePath = [
		'configDraft',
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];
	const newObjectToCheck = path(absolutePath, state);

	if (!newObjectToCheck || isEmpty(newObjectToCheck)) return false;

	const values = Object.values(newObjectToCheck);
	const newObjectToCheckStatus = values.map(redirect => {
		const redirectValues = Object.values(redirect);
		return redirectValues.some(element => isEmpty(element));
	});

	return newObjectToCheckStatus.some(element => element === true);
}
