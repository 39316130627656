// Auth0Service.js
// Just a simple wrapper around auth0-js

import auth0 from 'auth0-js';
import HttpService from '../services/http-service';

const http = new HttpService();

async function initialize() {
	const clientConfiguration = await http.getClientConfiguration();
	const auth = new auth0.WebAuth({
		domain: clientConfiguration.domain,
		clientID: clientConfiguration.clientID,
		redirectUri: clientConfiguration.redirectUri,
		audience: clientConfiguration.audience,
		responseType: clientConfiguration.responseType,
		scope: clientConfiguration.scope,
	});
	return auth;
}

const authPromise = initialize();

async function authorize() {
	const auth = await authPromise;
	auth.authorize();
}

async function logout() {
	const auth = await authPromise;
	auth.logout({ returnTo: window.location.href });
}

function setSession(authResult, callback) {
	const expiresAt = JSON.stringify(
		authResult.expiresIn * 1000 + new Date().getTime(),
	);

	localStorage.setItem('access_token', authResult.accessToken);
	localStorage.setItem('id_token', authResult.idToken);
	localStorage.setItem('expires_at', expiresAt);
	callback();
}

async function handleAuthorize(callback) {
	const auth = await authPromise;

	auth.parseHash((err, authResult) => {
		if (authResult && authResult.accessToken && authResult.idToken) {
			setSession(authResult, callback);
		}
		if (err) {
			console.log(err); // eslint-disable-line
		}
	});
}

function isAuthenticated() {
	const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '0');
	return new Date().getTime() < expiresAt;
}

async function getUserInformation() {
	const auth = await authPromise;
	const accessToken = localStorage.getItem('access_token');
	return new Promise((resolve) => {
		if (accessToken) {
			auth.client.userInfo(accessToken, (err, profile) => {
				resolve(profile || {});
			});
		} else {
			resolve({});
		}
	});
}

function deleteLocalStorage(callback) {
	if (localStorage.getItem('access_token')) {
		localStorage.removeItem('access_token');
	}
	if (localStorage.getItem('id_token')) {
		localStorage.removeItem('id_token');
	}
	if (localStorage.getItem('expires_at')) {
		localStorage.removeItem('expires_at');
	}
	callback();
	logout();
}

export {
	authorize,
	handleAuthorize,
	deleteLocalStorage,
	isAuthenticated,
	getUserInformation,
};
