import { path } from 'ramda';
import { getAppData } from '../selectors/getAppData';
import { getEditorIsDirty } from '../selectors/getEditorIsDirty';
import { getDataHasChanges } from '../selectors/getDataHasChanges';
import { getSelectedAppKey } from '../selectors/getSelectedAppKey';
import { getSelectedKey } from '../selectors/getSelectedKey';
import { PATH_DOMAINS, PATH_MAINTENANCE, PATH_REDIRECTS } from '../constants';
import { checkedHasValidationErrors } from './draftValidationStateActions';

export const UPDATED_DATA = 'editor/UPDATED_DATA';
export const CHANGES_SAVED = 'editor/CHANGES_SAVED';
export const DELETED_DATA = 'editor/DELETED_DATA';
export const VALIDATED_EDITOR = 'editor/VALIDATED_EDITOR';
export const FORMATTED_DATA = 'editor/FORMATTED_DATA';

export const updatedData = (newValue, configKey, selectedApp) => ({
	type: UPDATED_DATA,
	payload: {
		newValue,
		configKey,
		selectedApp,
	},
});

export const changesSaved = (newValue, configKey, selectedApp) => ({
	type: CHANGES_SAVED,
	payload: {
		newValue,
		configKey,
		selectedApp,
	},
});

export const deletedData = (configKey, selectedApp) => ({
	type: DELETED_DATA,
	payload: {
		configKey,
		selectedApp,
	},
});

export const validatedEditor = (configKey, selectedApp, hasErrors) => ({
	type: VALIDATED_EDITOR,
	payload: {
		configKey,
		selectedApp,
		hasErrors,
	},
});

export const formattedData = (configKey, selectedApp) => ({
	type: FORMATTED_DATA,
	payload: {
		configKey,
		selectedApp,
	},
});

export const updateEditorData = newValue =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(updatedData(newValue, configKey, selectedApp));

		if (!getEditorIsDirty(getState())) {
			dispatch(deletedData(configKey, selectedApp));
		}
	};

export const saveChanges = () =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		const newValue = path(
			['editorState', configKey, selectedApp, 'data'],
			state,
		);

		dispatch(changesSaved(JSON.parse(newValue), configKey, selectedApp));
		dispatch(deletedData(configKey, selectedApp));
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_MAINTENANCE,
			),
		);
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_REDIRECTS,
			),
		);
		dispatch(
			checkedHasValidationErrors(
				getState(),
				configKey,
				selectedApp,
				PATH_DOMAINS,
			),
		);
	};

export const validateEditor = hasErrors =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);

		if (selectedApp) {
			dispatch(validatedEditor(configKey, selectedApp, hasErrors));
		}
	};

export const formatData = () =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);
		dispatch(formattedData(configKey, selectedApp));

		if (!getEditorIsDirty(getState())) {
			dispatch(deletedData(configKey, selectedApp));
		}
	};

export const loadOriginalData = () =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);

		if (getDataHasChanges(state, null, selectedApp)) {
			const originalData = JSON.stringify(
				getAppData(state, null, 'original'),
				null,
				'\t',
			);
			dispatch(updatedData(originalData, configKey, selectedApp));
		}
	};

export const resetDataToDraft = () =>
	function(dispatch, getState) {
		const state = getState();
		const configKey = getSelectedKey(state);
		const selectedApp = getSelectedAppKey(state);

		dispatch(deletedData(configKey, selectedApp));
	};
