import { isEmpty, path } from 'ramda';

export function maintenanceValidator(state, configKey, selectedApp, PATHS) {
	const absolutePath = [
		'configDraft',
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];
	const newObjectToCheck = path(absolutePath, state);

	if (!newObjectToCheck || isEmpty(newObjectToCheck)) return false;

	const keys = Object.keys(newObjectToCheck);

	if (keys.length === 3) return false;

	return true;
}
