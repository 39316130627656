import { path } from 'ramda';
import { getSelectedKey } from './getSelectedKey';

export function getConfigKeyData(state, original) {
	const configKey = getSelectedKey(state);

	const originalRootPath = ['configOriginal', 'byKey', configKey];
	const draftRootPath = ['configDraft', 'byKey', configKey];

	if (original) {
		return path(originalRootPath, state);
	}
	return path(draftRootPath, state);
}
