import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { rootReducer } from '../reducers';

const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunkMiddleware)),
);

export { store };
