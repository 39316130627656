export const CHECKED_HAS_VALIDATION_ERRORS =
	'draftState/CHECKED_HAS_VALIDATION_ERRORS';

export const checkedHasValidationErrors = (
	currentState,
	configKey,
	selectedApp,
	PATHS,
) => ({
	type: CHECKED_HAS_VALIDATION_ERRORS,
	payload: {
		currentState,
		configKey,
		selectedApp,
		PATHS,
	},
});
