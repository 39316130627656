import { PATH_DOMAINS, PATH_MAINTENANCE, PATH_REDIRECTS } from '../constants';
import { maintenanceValidator } from '../validators/maintenanceValidator';
import { redirectsValidator } from '../validators/redirectsValidator';
import { daomainsValidator } from '../validators/domainsValidator';

export function getSuitableValidator(PATHS) {
	if (PATHS === PATH_MAINTENANCE) {
		return maintenanceValidator;
	}

	if (PATHS === PATH_REDIRECTS) {
		return redirectsValidator;
	}

	if (PATHS === PATH_DOMAINS) {
		return daomainsValidator;
	}

	return null;
}
