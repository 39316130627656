import 'whatwg-fetch';

const apiUrl = '/api/';

class HttpService {
	async getConfigData(selectedTenant, selectedEnv) {
		try {
			this.response = await fetch(
				`${apiUrl}tenant-config/${selectedTenant}?env=${selectedEnv}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Laser-Lemon-Auth': `Bearer ${localStorage.getItem(
							'access_token',
						)}`,
						'Access-Control-Allow-Headers': 'Laser-Lemon-Auth',
					},
				},
			);
			if (this.response.status === 200) {
				return this.response.json();
			}
			if (this.response.status === 400) {
				return {};
			}
			throw Error(`${this.response.status} ${this.response.statusText}`);
		} catch (error) {
			throw Error(error.message);
		}
	};

	async deleteCache(selectedEnv) {
		try {
			this.response = await fetch(
				`${apiUrl}tenant-cache?env=${selectedEnv}`,
				{
					method: 'DELETE',
				},
			);
			if (this.response.status === 200) {
				return true;
			}
			if (this.response.status === 400) {
				return false;
			}
			throw Error(`${this.response.status} ${this.response.statusText}`);
		} catch (error) {
			throw Error(error.message);
		}
	};

	async getClientConfiguration() {
		try {
			this.response = await fetch(`${apiUrl}client-configuration`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return this.response.json();
		} catch (error) {
			throw Error(error.message);
		}
	};

	async updateConfigData(selectedTenant, selectedEnv, newData) {
		try {
			this.response = await fetch(
				`${apiUrl}tenant-config/${selectedTenant}?env=${selectedEnv}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Laser-Lemon-Auth': `Bearer ${localStorage.getItem(
							'access_token',
						)}`,
						'Access-Control-Allow-Headers': 'Laser-Lemon-Auth',
					},
					body: newData,
				},
			);
			if (this.response.status !== 200) {
				throw Error(`${this.response.status} ${this.response.statusText}`);
			}
		} catch (error) {
			throw Error(error.message);
		}
	};
}

export default HttpService;
