import { path } from 'ramda';
import { getSelectedKey } from './getSelectedKey';
import { getSelectedAppKey } from './getSelectedAppKey';
import { getAppData } from './getAppData';

export function getEditorIsDirty(state, app) {
	const configKey = getSelectedKey(state);
	const selectedApp = app || getSelectedAppKey(state);
	const data = path(['editorState', configKey, selectedApp, 'data'], state);
	const draftData = JSON.stringify(getAppData(state), null, '\t');
	if (!data) return false;
	if (data === draftData) {
		return false;
	}
	return true;
}
