import {
	assocPath,
	dissocPath,
	insert,
	lensIndex,
	path,
	remove,
	set,
} from 'ramda';
import {
	ADDED_LIST_ITEM,
	ADDED_REDIRECT,
	APP_DATA_DISCARDED,
	CONFIG_DRAFT_UPDATED,
	CONFIG_LOCAL_DELETED,
	RECEIVED_CONFIGDATA,
	REMOVED_EXCLUDED_DOMAIN,
	REMOVED_LIST_ITEM,
	REMOVED_REDIRECT,
	UPDATED_LIST_ITEM,
	UPDATED_REDIRECTS_DATA,
} from '../actions/configActions';
import { CHANGES_SAVED } from '../actions/editorActions';
import { OVERALL_APP } from '../constants';

const initialState = {
	byKey: {},
};

function receiveConfigData(state, { payload }) {
	if (state.byKey[payload.key]) {
		return state;
	}
	return {
		...state,
		byKey: {
			...state.byKey,
			[payload.key]: payload.configData,
		},
	};
}

function updateData(state, { payload }) {
	const { newValue, configKey, selectedApp, PATHS } = payload;
	const isOverallAppSelected = selectedApp === OVERALL_APP;
	const absolutePath = isOverallAppSelected
		? ['byKey', configKey, 'query', ...PATHS]
		: [
			'byKey',
			configKey,
			'query',
			'qry.tenant.get-full',
			'data',
			'private',
			'apps',
			selectedApp,
			...PATHS,
		];
	return assocPath(absolutePath, newValue, state);
}

function discardData(state, { payload }) {
	const { configKey, selectedApp, originalValue, pathToDiscard } = payload;
	const isOverallAppSelected = selectedApp === OVERALL_APP;
	const absolutePath = isOverallAppSelected
		? ['byKey', configKey, 'query', ...pathToDiscard]
		: [
			'byKey',
			configKey,
			'query',
			'qry.tenant.get-full',
			'data',
			'private',
			'apps',
			selectedApp,
			...pathToDiscard,
		];

	if (!originalValue) {
		return dissocPath(absolutePath, state);
	}
	return assocPath(absolutePath, originalValue, state);
}

function saveChanges(state, { payload }) {
	const { newValue, configKey, selectedApp } = payload;
	const isOverallAppSelected = selectedApp === OVERALL_APP;
	const absolutePath = isOverallAppSelected
		? ['byKey', configKey, 'query']
		: [
			'byKey',
			configKey,
			'query',
			'qry.tenant.get-full',
			'data',
			'private',
			'apps',
			selectedApp,
		];

	return assocPath(
		absolutePath,
		newValue,
		state,
	);
}

function updateRedirects(state, { payload }) {
	const { newValue, configKey, selectedApp, PATHS, index, prop } = payload;

	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];

	const lens = lensIndex(index);

	const originalArray = path(absolutePath, state);
	const objectToChange = originalArray[index];
	const changedObject = assocPath([prop], newValue, objectToChange);

	const changedArray = set(lens, changedObject, originalArray);

	return assocPath(absolutePath, changedArray, state);
}

function addRedirect(state, { payload }) {
	const { newObject, configKey, selectedApp, PATHS } = payload;
	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];

	const oldArray = path(absolutePath, state);
	if (oldArray) {
		const newArray = insert(0, newObject, oldArray);
		return assocPath(absolutePath, newArray, state);
	}
	return assocPath(absolutePath, [newObject], state);
}

function removeRedirect(state, { payload }) {
	const { index, configKey, selectedApp, PATHS } = payload;
	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];

	const oldArray = path(absolutePath, state);
	if (oldArray.length <= 1) {
		return dissocPath(absolutePath, state);
	}
	const newArray = remove(index, 1, oldArray);
	return assocPath(absolutePath, newArray, state);
}

function addListItem(state, { payload }) {
	const { domain, configKey, selectedApp, PATHS } = payload;
	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];
	const oldArray = path(absolutePath, state);
	if (oldArray) {
		const newArray = insert(0, domain, oldArray);
		return assocPath(absolutePath, newArray, state);
	}
	return assocPath(absolutePath, [domain], state);
}

function removeExcludedDomain(state, { payload }) {
	const { domain, configKey, selectedApp, PATHS } = payload;
	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];

	const oldArray = path(absolutePath, state);
	// if (oldArray.length <= 1) {
	// 	return dissocPath(absolutePath, state);
	// }
	const index = oldArray.indexOf(domain);

	const newArray = remove(index, 1, oldArray);
	return assocPath(absolutePath, newArray, state);
}

function updateListItem(state, { payload }) {
	const { newValue, index, configKey, selectedApp, PATHS } = payload;
	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];

	const lens = lensIndex(index);

	const originalArray = path(absolutePath, state);

	const changedArray = set(lens, newValue, originalArray);
	return assocPath(absolutePath, changedArray, state);
}

function removeListItem(state, { payload }) {
	const { index, configKey, selectedApp, PATHS } = payload;
	const absolutePath = [
		'byKey',
		configKey,
		'query',
		'qry.tenant.get-full',
		'data',
		'private',
		'apps',
		selectedApp,
		...PATHS[0],
	];

	const oldArray = path(absolutePath, state);

	const newArray = remove(index, 1, oldArray);
	return assocPath(absolutePath, newArray, state);
}

function configLocalDelete(state, { payload }) {
	const { configKey } = payload;
	const absolutePath = ['byKey', configKey];
	return dissocPath(absolutePath, state);
}

// eslint-disable-next-line default-param-last
const configDraft = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVED_CONFIGDATA:
			return receiveConfigData(state, action);

		case CONFIG_DRAFT_UPDATED: {
			return updateData(state, action);
		}
		case APP_DATA_DISCARDED: {
			return discardData(state, action);
		}

		case CHANGES_SAVED: {
			return saveChanges(state, action);
		}

		case UPDATED_REDIRECTS_DATA: {
			return updateRedirects(state, action);
		}

		case ADDED_REDIRECT: {
			return addRedirect(state, action);
		}

		case REMOVED_REDIRECT: {
			return removeRedirect(state, action);
		}

		case REMOVED_EXCLUDED_DOMAIN: {
			return removeExcludedDomain(state, action);
		}

		case ADDED_LIST_ITEM: {
			return addListItem(state, action);
		}

		case REMOVED_LIST_ITEM: {
			return removeListItem(state, action);
		}

		case UPDATED_LIST_ITEM: {
			return updateListItem(state, action);
		}

		case CONFIG_LOCAL_DELETED: {
			return configLocalDelete(state, action);
		}

		default:
			return state;
	}
};

export { configDraft };
